import React from "react";
import "./Modal.scss";
import { Button } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { motion } from "framer-motion";
import Arrow from "../../Assets/Icons/arrow.png";
import { v4 as uuidv4 } from "uuid";

const Modal = (props) => {
  //Global States
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);
  const setUsername = useStoreActions((actions) => actions.setUsername);
  const setEmail = useStoreActions((actions) => actions.setEmail);
  const businessName = useStoreState((state) => state.businessName);

  //Local States
  const [emailError, setEmailError] = React.useState(false);
  const [usernameError, setUsernameError] = React.useState(false);

  if (!props.show) {
    return null;
  }

  //   const handleOverlayClick = (e) => {
  //     if (e.target === e.currentTarget) {
  //       // Close the modal when clicking on the overlay
  //       props.onClose();
  //     }
  //   };
  const checkValidations = () => {
    if (document.getElementById("email").value === "") {
      setEmailError(true);
    } else {
      if (!document.getElementById("email").value.includes("@")) {
        setEmailError(true);
        return;
      }
      setEmailError(false);
    }
    if (document.getElementById("username").value === "") {
      setUsernameError(true);
    }

    if (
      document.getElementById("email").value === "" ||
      document.getElementById("username").value === "" ||
      document.getElementById("email").value.includes("@") === false ||
      document.getElementById("username").value.length < 3
    ) {
      return;
    } else {
      localStorage.setItem(
        "username",
        document.getElementById("username").value
      );
      localStorage.setItem("email", document.getElementById("email").value);
      setLoggedIn(true);
      props.onClose();
    }
  };

  return (
    <>
      <div className="modal">
        <motion.div
          id="chatbox"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: "spring",
              damping: 20,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          style={{
            display: "flex",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">{props.title}</div>
            </div>

            <div
              className="modal-body"
              style={{ fontSize: props.fontSize ? props.fontSize : "" }}
            >
              {props.message}
              <div className="inputs">
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "spring",
                      damping: 20,
                      stiffness: 100,
                      restDelta: 0.001,
                    },
                  }}
                >
                  <input
                    id="email"
                    placeholder={
                      emailError ? "Please provide a valid email" : "Email address"
                    }
                    style={{
                      border: emailError ? "1px solid red" : "",
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "spring",
                      damping: 20,
                      stiffness: 100,
                      restDelta: 0.001,
                    },
                  }}
                >
                  <input
                    id="username"
                    style={{
                      marginTop: "1.313rem",
                      border: usernameError ? "1px solid red" : "",
                    }}
                    placeholder={
                      usernameError
                        ? "Please provide a valid name"
                        : "Your name"
                    }
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </motion.div>
              </div>
              <div className="below-inputs">
                <div
                  className="below-text"
                  onClick={() => {
                    const uniqueId = uuidv4();
                    localStorage.setItem("username", uniqueId);
                    localStorage.setItem(
                      "email",
                      `${uniqueId}@${businessName}.com`
                    );
                    props.onClose();
                    setLoggedIn(true);
                  }}
                >
                  Continue anonymously
                </div>
                <motion.div
                  whileHover={{ scale: 1.04 }}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "spring",
                      damping: 20,
                      stiffness: 100,
                      restDelta: 0.001,
                    },
                  }}
                >
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      checkValidations();
                    }}
                  >
                    {" "}
                    Continue <img src={Arrow} alt="Login" />
                  </Button>
                </motion.div>
              </div>
              {/* <button onClick={props.onClose}>Close</button> */}
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Modal;
